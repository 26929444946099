const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (/Linux/.test(platform)) {
        os = 'Linux'
    } else if (platform.indexOf('mac') !== -1) {
        os = 'Mac OS'
    }

    return os
}

const getExcerpt = (html, length = 160) => {
    const text = html.replace(/(<([^>]+)>)/gi, '')

    if (text.length > length) {
        return text.substring(0, length) + '...'
    }

    return text
}

function cn() {
    return [...arguments].filter(e => !!e).join(' ')
}

function pullParams() {
    // Assuming the URL is something like:
    // http://example.com/?utm_source=google&utm_medium=cpc&utm_campaign=spring_sale&utm_term=running+shoes&utm_content=logolink

    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_term = urlParams.get('utm_term');
    const utm_content = urlParams.get('utm_content');

    return { utm_source, utm_medium, utm_campaign, utm_term, utm_content}
}

function pullParams2() {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    const page = urlParams.get('page');

    return { search, page }
}

export { getOS, getExcerpt, cn, pullParams, pullParams2 }
