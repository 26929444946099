import Head from 'next/head'
const env = process.env.NEXT_PUBLIC_APP_ENV

export default function Meta({
    title = 'Master Longevity',
    description = 'Learn to balance every aspect of your being to create more energy and inner peace. Integrate scientifically proven methods into your day to experience life at its fullest.',
    image = '/images/social.png',
    noindex = false,
    appName = ' | Vital Habits'
}) {
    return (
        <Head>
            {(noindex || env !== 'prod') && <meta name="robots" content="noindex, nofollow" />}
            {title && (
                <>
                    <title>{`${title}${appName}`}</title>
                    <meta name="title" content={`${title} | Vital Habits`} />
                    <meta itemProp="name" content={`${title} | Vital Habits`} />
                    <meta property="og:title" content={`${title} | Vital Habits`} />
                </>
            )}
            {description && (
                <>
                    <meta property="og:description" content={description} />
                    <meta itemProp="description" content={description} />
                    <meta name="description" content={description} />
                </>
            )}
            <meta itemProp="image" content={image} />
            <meta property="og:image" content={image} />
            <meta name="twitter:image" content={image} />
        </Head>
    )
}
