import { useCallback } from "react"
import Cookies from 'universal-cookie'

function useTrack() {
    const cookies = new Cookies();

    return useCallback((event, properties) => {
        if (window.analytics) {

            const amp_id = cookies.get('vhAmpId') || 'no_amp_id'

            window.analytics.track(event, {amp_id, ...properties})
        }
    }, [])
}

export default useTrack